import React from 'react';

import Layout from 'common/Layout';
import SimpleHeader from 'components/sections/SimpleHeader';
import Footer from 'components/sections/Footer';

import { Container } from 'components/global';

import theme from '../theme';

const PrivacyPolicy = () => (
  <Layout>
    <SimpleHeader />
    <Container style={{ fontSize: '13px', lineHeight: '1.6em' }}>
      <h3 style={{ color: theme.color.blue.regular }}>PRIVACY POLICY</h3>
      <p>Last modified: June 17, 2020 </p>
      <h4 style={{ color: theme.color.blue.regular }}>INTRODUCTION</h4>
      <p>
        ETHGlobal Inc. (“<b>ETHGlobal</b>”, “<b>us</b>” or 
        <em>
          <b>we</b>
        </em>
        ) respect your privacy and are committed to protecting it by complying
        with this policy.
      </p>
      <br />
      <p>This policy describes:</p>
      <ul>
        <li>
          How we collect, use, disclose, and protect the personal information of
          our customers, event participants, community members and website users
          (“<b>you</b>”).{' '}
        </li>
        <li>
          The types of information we may collect from you or that you may
          provide when you sign up for or attend events, respond to surveys or
          electronic communications, or visit the website{' '}
          <a href="http://www.ethglobal.co/">www.ethglobal.co</a> or any related
          websites we operate.
        </li>
        <li>
          Our practices for collecting, using, maintaining, protecting, and
          disclosing that information.{' '}
        </li>
      </ul>
      <p>
        Privacy laws in Canada generally define “personal information” as any
        information about an identifiable individual, which includes information
        that can be used on its own or with other information to identify,
        contact, or locate a single person.{' '}
      </p>
      <br />
      <p>
        This policy applies to information we collect, use, or disclose about
        you:
      </p>
      <ul>
        <li>
          On the ETHGlobal website or any related websites operated by us,
          including event-specific websites.{' '}
        </li>
        <li>
          Relating to events organized by ETHGlobal, including any information
          gathered during the event registration process or over the course of
          the event itself.{' '}
        </li>
        <li>
          In email, text, and other electronic messages between you and
          ETHGlobal.{' '}
        </li>
        <li>
          Through mobile and desktop applications you download from this
          website, or which are used for ETHGlobal events.{' '}
        </li>
        <li>
          When you interact with our advertising and applications on third-party
          websites and services if those applications or advertising include
          links to this policy.{' '}
        </li>
        <li>Through any surveys organized by ETHGlobal. </li>
      </ul>
      <p>
        Our website may include links to third-party websites, plug-ins,
        services, social networks, or applications. Clicking on those links or
        enabling those connections may allow the third party to collect or share
        data about you. If you follow a link to a third-party website or engage
        a third-party plugin, please note that these third parties have their
        own privacy policies and we do not accept any responsibility or
        liability for these policies. We do not control these third-party
        websites, and we encourage you to read the privacy policy of every
        website you visit.
      </p>
      <br />
      <p>
        Please read this policy carefully to understand our policies and
        practices for collecting, processing, and storing your information. If
        you do not agree with our policies and practices, your choice is not to
        use our website or any program or service we offer through our website
        or that is linked to this policy. Similarly, if you do not agree with
        our policies and practices, you are not eligible to participate in our
        events, programs or services.
      </p>
      <br />
      <p>
        In order to actively participate in an ETHGlobal event, program or
        service, you must affirmatively agree to this policy. By accessing or
        using this website or any program or service we offer through our
        website or that is linked to this policy, you indicate that you
        understand, accept, and consent to the practices described in this
        policy. This policy may change from time to time (see “Changes to Our
        Privacy Policy” below). Your continued use of this website or any
        program or service we offer through our website or that is linked to
        this policy after we make changes indicates that you accept and consent
        to those changes, so please check the policy periodically for updates.
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        INFORMATION WE COLLECT ABOUT YOU
      </h4>
      We collect and use several types of information from and about you,
      <ul>
        <li>
          <b>Personal information</b>, that we can reasonably use to directly or
          indirectly identify you, such as your name, age, e-mail address,
          Internet protocol (IP) address, user name or other similar identifier,
          ethereum account address, location, event and developer feedback, and
          any other identifier we may use to contact you (“
          <b>personal information</b>”).
        </li>
      </ul>
      We provide an opportunity for any user to unsubscribe from or opt-out of
      contact for marketing purposes on an ongoing basis by using the
      unsubscribe mechanism at the bottom of our e-mails, or e-mailing us
      <ul>
        <li>
          <b>Non-personal information</b>, which means information that does not
          directly or indirectly reveal your identity or directly relate to an
          identifiable individual, such as or statistical or aggregated
          information. We may derive non-personal statistical or aggregated data
          from personal information. For example, we may aggregate personal
          information to calculate the percentage of users accessing a specific
          website feature.{' '}
        </li>
        <li>
          <b>Technical information</b>, including your login information,
          browser type and version, time zone setting, browser plug-in types and
          versions, operating system and platform, or information about your
          internet connection, the equipment you use to access our website(s),
          and usage details.{' '}
        </li>
        <li>
          <b>Non-personal details about your website interactions</b>, including
          the full Uniform Resource Locators (URLs), clickstream to, through and
          from our website(s) (including date and time), products you viewed or
          searched for, page response times, download errors, length of visits
          to certain pages, page interaction information (such as scrolling,
          clicks, and mouse-overs), methods used to browse away from the page,
          or any phone number used to call our customer service number.{' '}
        </li>
      </ul>
      <h4 style={{ color: theme.color.blue.regular }}>
        HOW WE COLLECT INFORMATION ABOUT YOU
      </h4>
      <p>
        We use different methods to collect your information, including through:
      </p>
      <br />
      <li>
        <b>Direct interactions with you when you provide it to us</b>, for
        example, by filling in forms and surveys, or corresponding with us by
        phone, email, or otherwise.{' '}
      </li>
      <li>
        <b>Your participation in our events</b> may also provide information for
        us to publish or display through participating in our events.{' '}
      </li>
      <li>
        <b>Automated technologies or interactions</b> as you navigate through
        our website. Information collected automatically may include usage
        details, IP addresses, and information collected through cookies and
        other tracking technologies.{' '}
      </li>
      <li>
        <b>Third parties or publicly available sources</b>, for example, our
        business partners.{' '}
      </li>
      <h5 style={{ fontSize: '1.2em', color: theme.color.blue.regular }}>
        Information you provide to us
      </h5>
      <p>
        The information we collect directly from you on or through our websites
        may include:
      </p>
      <ul>
        <li>
          Information that you provide by filling in forms. This includes
          information provided at the time of registering for an event,
          subscribing to any service we may offer from time to time, or
          requesting information or services from us. We may also ask you for
          information when you enter a contest or promotion sponsored by us, and
          when you report a problem with our websites or services.
        </li>
        <li>
          Records and copies of your correspondence (including email addresses),
          if you contact us.
        </li>
        <li>
          Your responses to surveys that we might ask you to complete for
          research or other purposes.
        </li>
        <li>
          Details of transactions you carry out through our websites or those of
          our technology partners.{' '}
        </li>
        <li>Your search queries on our website.</li>
      </ul>
      <h5 style={{ fontSize: '1.2em', color: theme.color.blue.regular }}>
        Information We Collect Through Cookies and Other Automatic Data
        Collection Technologies
      </h5>
      <p>
        As you navigate through and interact with any of our websites, we may
        use cookies or other automatic data collection technologies to collect
        certain information about your equipment, browsing actions, and
        patterns, including:
      </p>
      <ul>
        <li>
          Details of your visits to our websites, including traffic data,
          location data, logs, and other communication data and the resources
          that you access and use on a website.
        </li>
        <li>
          Information about your computer and internet connection, including
          your IP address, operating system, and browser type.
        </li>
      </ul>
      <p>
        The information we collect automatically is statistical information and
        may include personal information, and we may maintain it or associate it
        with personal information we collect in other ways or receive from third
        parties. It helps us to improve our websites and to deliver a better and
        more personalized service, including by enabling us to:
      </p>
      <ul>
        <li>Estimate our audience size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize our
          websites according to your individual interests.
        </li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you return to our websites.</li>
      </ul>
      <p>
        The technologies we use for this automatic data collection may include:
      </p>
      <ul>
        <li>
          <b>Cookies (or browser cookies).</b> A cookie is a small file placed
          on the hard drive of your computer. You may refuse to accept browser
          cookies by activating the appropriate setting on your browser.
          However, if you select this setting you may be unable to access
          certain parts of our Website. Unless you have adjusted your browser
          setting so that it will refuse cookies, our system will issue cookies
          when you direct your browser to our Website.
        </li>
        <li>
          <b>Flash Cookies.</b> Certain features of our Website may use local
          stored objects (or Flash cookies) to collect and store information
          about your preferences and navigation to, from, and on our Website.
          Flash cookies are not managed by the same browser settings that are
          used for browser cookies.
        </li>
        <li>
          <b>Web Beacons.</b> Pages of our Website and our e-mails may contain
          small electronic files known as web beacons (also referred to as clear
          gifs, pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or opened an
          email and for other related website statistics (for example, recording
          the popularity of certain website content and verifying system and
          server integrity)
        </li>
      </ul>
      <h4 style={{ color: theme.color.blue.regular }}>
        HOW WE USE YOUR INFORMATION
      </h4>
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information:{' '}
      </p>
      <ul>
        <li>
          To provide you with information, products, or services that you
          request from us.
        </li>
        <li>
          To produce and carry out our events in a way that encourages
          development and connections between participants, sponsors and other
          partners. This includes connecting event participants with sponsor
          companies and partners who are offer platforms and services that may
          be useful for event participants, and vice versa.
        </li>
        <li>To present our websites and its contents to you.</li>
        <li>
          To fulfill the purposes for which you provided the information or that
          were described when it was collected, or any other purpose for which
          you provide it.
        </li>
        <li>
          To provide our partners and sponsors with important feedback about the
          Ethereum developer community based on your feedback and survey
          responses.
        </li>
        <li>
          To carry out our obligations and enforce our rights arising from any
          contracts with you, including for billing and collection or to comply
          with legal requirements.
        </li>
        <li>
          To notify you about changes to our events, websites or any products or
          services we offer or provide.
        </li>
        <li>
          To improve our events, websites, products or services, marketing, or
          customer relationships and experiences.
        </li>
        <li>
          To allow you to participate in interactive features, social media, or
          similar features at our events or on our websites.
        </li>
        <li>
          In any other way we may describe when you provide the information.
        </li>
        <li>For any other purpose with your consent.</li>
      </ul>
      <h4 style={{ color: theme.color.blue.regular }}>
        DISCLOSURE AND TRANSFER OF YOUR INFORMATION{' '}
      </h4>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>
      <br />
      <p>
        We may disclose or otherwise transfer personal information that we
        collect or you provide as described in this privacy policy:
      </p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          In accordance with applicable law, to a buyer or other successor in
          the event of a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of ETHGlobal
          Inc.'s assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which personal information held
          by ETHGlobal about our customers and users is among the assets
          transferred.
        </li>
        <li>
          To third party event sponsors and partners to contact you, which may
          include marketing their products and services to you. The purpose of
          these disclosures is to connect event participants with relevant
          sponsors and partners in order to enhance the experience of event
          participants and improve the likelihood of success of their projects.
          Our events and event participants are often most successful when
          participants develop new tools and software that build on existing
          tools and software owned and operated by our event sponsors and
          partners. Sponsors and partners offer valuable advice and guidance to
          event participants on their projects. Connecting participants with
          sponsors and partners is an integral part of our events and creates a
          more meaningful experience for participants.
        </li>
        <li>
          With respect to survey responses, to give sponsors and partners
          feedback on events, products and services.
        </li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business (such as analytics and search engine providers
          that assist us with website improvement and optimization) and who are
          contractually obligated to keep personal information confidential, use
          it only for the purposes for which we disclose it to them, and to
          process the personal information with the same standards set out in
          this policy.
        </li>
        <li>To fulfill the purpose for which you provide it.</li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
      </ul>
      <p>We may also disclose your personal information: </p>
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request, in accordance with
          applicable law.
        </li>
        <li>
          To enforce or apply our terms of use and other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of ETHGlobal Inc., our customers, or
          others.
        </li>
      </ul>
      <p>
        ETHGlobal is based in Canada. However, we may process, store, and
        transfer your personal information in and to a foreign country, with
        different privacy laws that may or may not be as comprehensive as
        Canadian law. In these circumstances, the governments, courts, law
        enforcement, or regulatory agencies of that country may be able to
        obtain access to your personal information through the laws of the
        foreign country.
      </p>
      <br />
      <p>
        By submitting your personal information or engaging with our websites,
        you consent to this transfer, storage, or processing.
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
      </h4>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <ul>
        <li>
          <b>Tracking Technologies and Advertising.</b> You can set your browser
          to refuse all or some browser cookies, or to alert you when cookies
          are being sent. To learn how you can manage your Flash cookie
          settings, visit the Flash player settings page on Adobe's website. If
          you disable or refuse cookies, please note that some parts of this
          Website may not be accessible or may not function properly.{' '}
        </li>
        <li>
          <b>Promotional Offers from the Company.</b> If you have opted in to
          receive certain emails from us but no longer wish to have your email
          address used by the Company to promote our own or third parties'
          products or services, you can opt-out by sending us an email stating
          your request to hello@ethglobal.co. If we have sent you a promotional
          email, you may unsubscribe by clicking the unsubscribe link we have
          included in the email. This opt-out does not apply to information
          provided to the Company as part of an ongoing event, service
          experience, or other ongoing transactions.{' '}
        </li>
      </ul>
      <p>
        However, if you do not consent to the ways we collect, use, disclose and
        transfer your information, you will not be eligible to participate in
        our events, surveys, or use our websites.{' '}
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>DATA SECURITY </h4>
      <p>
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure.{' '}
      </p>
      <br />
      <p>
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of our Website, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone. Unfortunately, the transmission of information via the Internet
        is not completely secure. Although we do our best to protect your
        personal information, we cannot guarantee the security of your personal
        information transmitted to our Website. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Website.{' '}
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>DATA RETENTION</h4>
      <p>
        Except as otherwise permitted or required by applicable law or
        regulation, we will only retain your personal information for as long as
        necessary to fulfill the purposes we collected it for, including for the
        purposes of satisfying any legal, accounting, or reporting requirements.
        Under some circumstances we may anonymize your personal information so
        that it can no longer be associated with you. We reserve the right to
        use such anonymous and de-identified data for any legitimate business
        purpose without further notice to you or your consent.
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        CHILDREN UNDER THE AGE OF 13
      </h4>
      <p>
        Our Website is not intended for children under 13 years of age. No one
        under age 13 may provide any information to us or on our websites. We do
        not knowingly collect personal information from children under 13. If
        you are under 13, do not use or provide any information on our websites
        or provide any information about yourself to us, including your name,
        address, telephone number, email address, or any screen name or user
        name you may use. If we learn we have collected or received personal
        information from a child under 13 without verification of parental
        consent, we will delete that information. If you believe we might have
        any information from or about a child under 13, please contact us at
        hello@ethglobal.co
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION
      </h4>
      <p>
        It is important that the personal information we hold about you is
        accurate and current. Please keep us informed if your personal
        information changes. By law you have the right to request access to and
        to correct the personal information that we hold about you.
      </p>
      <br />
      <p>
        We may request specific information from you to help us confirm your
        identity and your right to access, and to provide you with the personal
        information that we hold about you or make your requested changes.
        Applicable law may allow or require us to refuse to provide you with
        access to some or all of the personal information that we hold about
        you, or we may have destroyed, erased, or made your personal information
        anonymous in accordance with our record retention obligations and
        practices. If we cannot provide you with access to your personal
        information, we will inform you of the reasons why, subject to any legal
        or regulatory restrictions.{' '}
      </p>
      <br />
      <p>
        If you are concerned about our response or would like to correct the
        information provided, you may contact us at hello@ethglobal.co{' '}
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        WITHDRAWING YOUR CONSENT{' '}
      </h4>
      <p>
        Where you have provided your consent to the collection, use, and
        transfer of your personal information, you may have the legal right to
        withdraw your consent under certain circumstances. To withdraw your
        consent, if applicable, contact us at hello@ethglobal.co. Please note
        that if you withdraw your consent we may not be able to provide you with
        a particular product or service. We will explain the impact to you at
        the time to help you with your decision.
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        CHANGES TO OUR PRIVACY POLICY{' '}
      </h4>
      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page. If we make material changes to how we treat our users'
        personal information, we will notify you through a notice on the website
        home page.{' '}
      </p>
      <br />
      <p>
        We include the date the privacy policy was last revised at the top of
        the page. You are responsible for ensuring we have an up-to-date,
        active, and deliverable email address for you, and for periodically
        visiting our Website and this privacy policy to check for any changes.{' '}
      </p>
      <h4 style={{ color: theme.color.blue.regular }}>
        CONTACT INFORMATION AND CHALLENGING COMPLIANCE
      </h4>
      <p>
        We welcome your questions, comments, and requests regarding this privacy
        policy and our privacy practices. Please contact us at:
      </p>
      <br />
      Josh Stark, Co-founder:
      <a href="mailto: hello@ethglobal.co"> hello@ethglobal.co</a>
      <br />
      <p>
        We have procedures in place to receive and respond to complaints or
        inquiries about our handling of personal information, our compliance
        with this policy, and with applicable privacy laws. To discuss our
        compliance with this policy please contact us using the contact
        information listed above.
      </p>
      <br />
    </Container>
    <Footer style={{ marginTop: '100px' }} />
  </Layout>
);

export default PrivacyPolicy;
